import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFRM,
  isTLA,
  isKYCApp,
  loadUserTariffs,
  LocalizePropType,
  selectTariffs,
  selectUser,
  updateUser,
  withTextLocalizer,
  uploadDocument,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import TariffView from './TariffView';
import {
  getDLVerificationStatus,
  getIDVerificationStatus,
  isDashboardScreen,
  isDLVerificationRequired,
  isIDVerificationRequired,
  navigateTo,
  showAlert,
  showAlertWithOptions,
  TALLY_LINKS,
} from '../../../../utils';
import { TARIFF_CONSENT_STATUS } from '../../../../enums/Tariff';
import { useFilePicker } from 'use-file-picker';

interface Values {}

type Props = {
  localize: LocalizePropType;
};

const TariffContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tariffs = useSelector(selectTariffs);
  const user = useSelector(selectUser);
  const [tariffConsentStatus, setTariffConsentStatus] = useState(
    TARIFF_CONSENT_STATUS.OPEN
  );
  const [tariffSelected, setTariffSelected] = useState(null);
  const [upsellingSelected, setUpsellingSelected] = useState(null);
  const [tlaExtraTariffs, setTlaExtraTariffs] = useState([]);
  const [selectedTlaExtraOption, setSelectedTlaExtraOption] = useState(null);
  const displayTariffs = isTLA()
    ? selectedTlaExtraOption ||
      tariffs?.filter(
        (tariff) =>
          !(
            tariff?.name.startsWith('Rahmentarif') &&
            tariff?.id !== '6661aeeb02a17c06e80801c7'
          )
      )
    : tariffs;
  const [subscriptionNum, setSubscriptionNum] = useState('');
  const [openFileSelector, { filesContent, loading, clear: clearFile }] =
    useFilePicker({
      readAs: 'DataURL',
      accept: 'image/*',
      multiple: false,
      limitFilesConfig: { max: 1 },
      maxFileSize: 50,
    });

  const onTariffSelected = (tariff) => {
    setTariffSelected(tariff);
    setUpsellingSelected(null);
  };

  const onSubmit = () => {
    const extraData = user?.extraData ? JSON.parse(user?.extraData) : {};
    if (selectedTlaExtraOption && !filesContent?.[0]?.content) return;
    if (tariffSelected) {
      dispatch(
        // @ts-ignore
        updateUser(
          {
            ...(subscriptionNum && {
              extraData: JSON.stringify({ subscriptionNum: subscriptionNum }),
            }),
            tariff: {
              id: tariffSelected.id,
              upsellings:
                upsellingSelected || selectedTlaExtraOption
                  ? [
                      {
                        ...(upsellingSelected && { code: upsellingSelected }),
                      },
                    ]
                  : [],
            },
          },
          (user) => {
            if (filesContent?.[0]?.content) {
              dispatch(
                uploadDocument(filesContent?.[0]?.content, 'OTHER', () => {})
              );
            }
            if (
              getIDVerificationStatus(user) === 'completed' &&
              getDLVerificationStatus(user) === 'completed'
            ) {
              navigateTo(
                history,
                isDashboardScreen() ? '/settings' : '/onboarding/optimizedSepa'
              );
            } else if (isFRM()) {
              if (extraData?.referral) {
                navigateTo(
                  history,
                  isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
                );
              } else {
                navigateTo(
                  history,
                  isDashboardScreen()
                    ? `/dashboard/dynamicForm?url=${
                        extraData?.partner
                          ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                          : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
                      }`
                    : `/onboarding/dynamicForm?url=${
                        extraData?.partner
                          ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                          : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
                      }`
                );
              }
            } else {
              if (
                !isDLVerificationRequired(user) &&
                !isIDVerificationRequired(user)
              ) {
                navigateTo(
                  history,
                  isDashboardScreen()
                    ? '/settings'
                    : '/onboarding/optimizedSepa'
                );
              } else {
                navigateTo(
                  history,
                  isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
                );
              }
            }
          }
        )
      );
    }
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onClickExtraOptions = () => {
    const options = tlaExtraTariffs.map((tariff) => ({
      title: tariff.name,
      action: () => setSelectedTlaExtraOption([tariff]),
    }));
    showAlertWithOptions(localize('tariff.tla.extra'), '', options);
  };

  const onResetExtraOptions = () => {
    setSelectedTlaExtraOption(null);
  };

  useEffect(() => {
    dispatch(loadUserTariffs());
  }, []);

  useEffect(() => {
    if (!user?.tariff?.id) {
      if (tariffs?.length === 1) {
        setTariffSelected(tariffs[0]);
      }
    } else if (tariffs && user?.tariff?.id) {
      setTariffSelected(
        tariffs?.find((tariff) => tariff?.id === user?.tariff?.id)
      );
      setTariffConsentStatus(TARIFF_CONSENT_STATUS.APPROVED);
      if (user?.tariff?.upsellings) {
        setUpsellingSelected(user?.tariff?.upsellings?.[0]?.code);
      }
    }

    if (isTLA() && tariffs) {
      const filteredTariffs = tariffs.filter(
        (tariff) =>
          tariff.name.startsWith('Rahmentarif') &&
          tariff.id !== '6661aeeb02a17c06e80801c7'
      );
      setTlaExtraTariffs(filteredTariffs);
    }
  }, [tariffs, user]);

  return (
    <TariffView
      tariffs={displayTariffs}
      tlaExtraTariffs={tlaExtraTariffs}
      localize={localize}
      onClose={onClose}
      tariffSelected={tariffSelected}
      setTariffSelected={onTariffSelected}
      tariffConsentStatus={tariffConsentStatus}
      setTariffConsentStatus={setTariffConsentStatus}
      onSubmit={onSubmit}
      upsellingSelected={upsellingSelected}
      setUpsellingSelected={setUpsellingSelected}
      onClickExtraOptions={onClickExtraOptions}
      selectedTlaExtraOption={selectedTlaExtraOption}
      onResetExtraOptions={onResetExtraOptions}
      openFileSelector={openFileSelector}
      fileLoading={loading}
      filesContent={filesContent}
      clearFile={clearFile}
      subscriptionNum={subscriptionNum}
      setSubscriptionNum={setSubscriptionNum}
    />
  );
};

export default withTextLocalizer(TariffContainer);
