import { bicPattern, ibanPattern, UserDataOutput } from 'getaway-data-layer';
import * as yup from 'yup';
import { format, addYears } from 'date-fns';
import { validateDateOfBirth } from '../utils';

export const minBirthDate = addYears(
  new Date(new Date().getFullYear(), 0, 1),
  -90
);
export const maxBirthDate = addYears(
  new Date(new Date().getFullYear(), 0, 1),
  -17
);

export const AddSepaModal = (
  user: any,
  reduxDateOfBirth: string,
  iban: string,
  bic: string
) => ({
  name: user ? `${user?.firstName} ${user?.lastName}` : '',
  iban: iban,
  bic: bic,
  dateOfBirth: user?.dateOfBirth
    ? format(new Date(user?.dateOfBirth), 'yyyy-MM-dd')
    : reduxDateOfBirth
    ? format(new Date(reduxDateOfBirth), 'yyyy-MM-dd')
    : '',
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
});

export const AddSepaSchema = (
  localize: any,
  user: UserDataOutput,
  iban: string,
  bicMandatory: boolean,
  allowedSEPACountries: string[] | null
): any =>
  yup.object({
    iban: yup
      .string()
      .required(localize('validation.required'))
      .matches(ibanPattern(iban), localize('input.error.invalid'))
      .test(
        'is-allowed-country',
        localize('input.error.invalidCountry'),
        (value) => {
          if (!value) return false;
          const countryCode = value.substring(0, 2).toUpperCase();
          return (
            !allowedSEPACountries ||
            allowedSEPACountries.length === 0 ||
            allowedSEPACountries?.includes(countryCode?.toLowerCase())
          );
        }
      ),
    bic: yup.string().when([], {
      is: () => bicMandatory && !iban.startsWith('DE'),
      then: yup
        .string()
        .required(localize('validation.required'))
        .matches(bicPattern, localize('input.error.invalid')),
      otherwise: yup.string(),
    }),
    dateOfBirth:
      user.userType === 'org'
        ? yup.string()
        : yup
            .string()
            .required(localize('validation.required'))
            .test(
              'is-date-in-range',
              localize('input.error.invalid'),
              (value) => {
                return validateDateOfBirth(value, minBirthDate, maxBirthDate);
              }
            ),
  });
