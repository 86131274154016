import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isKYCApp,
  joinCircle,
  selectAuth,
  selectBookings,
  selectDeeplink,
  selectUser,
  setShowHomeWT,
} from 'getaway-data-layer';
import { useQuery } from '../../hooks/useQuery';

const LandingContainer = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectUser);
  const basicAuth = useSelector(selectAuth);
  const user = authUser || basicAuth;
  const deeplink = useSelector(selectDeeplink);
  const bookings = useSelector(selectBookings) as any;
  const history = useHistory();
  const query = useQuery();

  const activeConsentBookingsList =
    bookings
      ?.filter(
        (booking: { status: string }) =>
          ['offered'].includes(booking.status) &&
          // @ts-ignore
          booking?.bookingType !== 'express'
      )
      // @ts-ignore
      ?.sort((a, b) => new Date(a?.from) - new Date(b?.from)) || [];

  useEffect(() => {
    if (isKYCApp() && !user) dispatch(setShowHomeWT(true));
  }, []);

  if (
    history?.location?.pathname?.includes('/circle/join') &&
    user &&
    query.get('id')
  ) {
    dispatch(joinCircle(query.get('id')));
  }

  return history?.location?.pathname?.includes('/circle/join') && user ? (
    <Redirect
      to={{
        pathname: '/settings',
      }}
    />
  ) : activeConsentBookingsList?.length > 0 ? (
    <Redirect
      to={{ pathname: `booking/consent/${activeConsentBookingsList[0]?.id}` }}
    />
  ) : user ? (
    <Redirect to={{ pathname: isKYCApp() ? '/settings' : '/map' }} />
  ) : (
    <Redirect
      to={{
        pathname:
          deeplink && !['/', '/login', '/signup'].includes(deeplink)
            ? '/login'
            : isKYCApp()
            ? '/' + history?.location?.search
            : '/map',
      }}
    />
  );
};

export default LandingContainer;
