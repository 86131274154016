import React from 'react';
import { format } from 'date-fns';
import './OptimizedSepaView.less';
import { FormikValues } from 'formik';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import {
  getBrandColor,
  LocalizePropType,
  UserDataOutput,
  formattedAddress,
  isLocalizeAdded,
  isKYCApp,
} from 'getaway-data-layer';
import { GA_TITLES, isDashboardScreen } from '../../../../../utils';
import Hyperlink from 'react-native-hyperlink';
// @ts-ignore
import { Linking } from 'react-native-web';
import ExpandableItem from '../../../../components/ExpandableItem/ExpandableItem';
import ListItem from '../../../../components/ListItem/ListItem';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  formik: FormikValues;
  user: UserDataOutput;
  onClose: () => void;
  expanded: boolean;
  onIbanChanged: (value: string) => void;
  onExpandPressed: (value: boolean) => void;
  addressStatus: any;
  address: any;
  onPressAddress: () => void;
  onPressAddressInProgress: () => void;
  onPressAddressCompleted: () => void;
  customError: string;
  maxDate: any;
  minDate: any;
  dateFormat: string;
  bicMandatory: boolean;
  onBicChange: (value: string) => void;
};

const OptimizedSepaView = ({
  localize,
  formik,
  user,
  onClose,
  expanded,
  onIbanChanged,
  onExpandPressed,
  addressStatus,
  address,
  onPressAddress,
  onPressAddressInProgress,
  onPressAddressCompleted,
  customError,
  dateFormat,
  maxDate,
  minDate,
  bicMandatory,
  onBicChange,
}: Props) => {
  const isUserInfoFilled =
    (user?.firstName || formik.values?.firstName) &&
    (user?.lastName || formik.values?.lastName) &&
    (user.userType === 'org' ||
      user?.dateOfBirth ||
      formik.values?.dateOfBirth) &&
    (user?.address?.city || formik.values?.address?.city);
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('firstSteps.payment.optimized.sepa.title')}
        description={localize('firstSteps.payment.optimized.sepa.desc')}
        showHelp
        showArrowBack={!isKYCApp()}
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'sepa.optimized.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'sepa.optimized.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <TextField
              required
              fullWidth
              id="iban"
              className="sepa-form-input"
              label={localize('firstSteps.payment.sepa.iban.placeholder')}
              helperText={(formik.touched.iban && formik.errors.iban) || ''}
              error={formik.touched.iban && Boolean(formik.errors.iban)}
              variant="standard"
              autoComplete="iban"
              type="text"
              {...formik.getFieldProps('iban')}
              onChange={onIbanChanged}
            />
            {bicMandatory &&
              formik?.values?.iban &&
              formik?.values?.iban?.length > 2 &&
              !formik?.values?.iban?.startsWith('DE') && (
                <TextField
                  fullWidth
                  id="bic"
                  className="bic-form-input"
                  label={localize('firstSteps.payment.bic.placeholder')}
                  helperText={(formik.touched.bic && formik.errors.bic) || ''}
                  error={formik.touched.bic && Boolean(formik.errors.bic)}
                  variant="standard"
                  autoComplete="bic"
                  type="text"
                  {...formik.getFieldProps('bic')}
                  onChange={onBicChange}
                />
              )}
            <ExpandableItem
              value={''}
              containerStyles={{ marginTop: 0 }}
              titleClassName={'payment-user-header'}
              title={localize('firstSteps.payment.sepa.info')}
              showSeparator={false}
              initiallyOpened={!isUserInfoFilled}
              showIncomplete={!isUserInfoFilled}
            >
              <div className="payment-user-content">
                {user?.userType == 'org' ? (
                  <ListItem
                    title={`${user.companyName.trim()} ${
                      user?.legalType || user?.otherLegalType
                    }`}
                    leftItem={
                      <img
                        style={{
                          width: 26,
                          height: 26,
                          marginRight: 35,
                          objectFit: 'contain',
                          position: 'relative',
                        }}
                        src="/images/businessPayment.png"
                        alt="user"
                      />
                    }
                  />
                ) : (
                  <>
                    {user?.firstNameVerified && user?.lastNameVerified && (
                      <ListItem
                        title={`${user.firstName.trim()} ${user.lastName.trim()} ${
                          user?.dateOfBirthVerified
                            ? `, ${format(
                                new Date(user.dateOfBirth),
                                'dd.MM.yyyy'
                              )}`
                            : ''
                        }`}
                        leftItem={
                          <div>
                            {user?.firstNameVerified &&
                            user?.lastNameVerified &&
                            user?.dateOfBirthVerified ? (
                              <img
                                alt="dashedPath"
                                src="/images/userVerified.png"
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 27,
                                }}
                              />
                            ) : (
                              <img
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 35,
                                  objectFit: 'contain',
                                  position: 'relative',
                                }}
                                src="/images/user.png"
                                alt="user"
                              />
                            )}
                          </div>
                        }
                        greyTextColor={
                          user?.firstNameVerified && user?.lastNameVerified
                        }
                        verified={
                          user?.firstNameVerified && user?.lastNameVerified
                        }
                      />
                    )}
                    {(!user?.firstNameVerified || !user?.lastNameVerified!) && (
                      <div style={{ marginTop: 12, display: 'flex' }}>
                        <img
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 35,
                            marginTop: 3,
                            objectFit: 'contain',
                            position: 'relative',
                          }}
                          src="/images/user.png"
                          alt="user"
                        />
                        <TextField
                          required
                          id="firstName"
                          sx={{ margin: '0px 8px 0px 0px' }}
                          label={localize('signup.name.placeholder')}
                          helperText={
                            (formik.touched.firstName &&
                              formik.errors.firstName) ||
                            ''
                          }
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          variant="standard"
                          size="small"
                          type="text"
                          autoComplete="given-name"
                          {...formik.getFieldProps('firstName')}
                        />
                        <TextField
                          required
                          id="lastName"
                          // sx={{ width: 0.4 }}
                          label={localize(
                            'signup.facebook.lastname.placeholder'
                          )}
                          helperText={
                            (formik.touched.lastName &&
                              formik.errors.lastName) ||
                            ''
                          }
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          variant="standard"
                          size="small"
                          type="text"
                          autoComplete="family-name"
                          {...formik.getFieldProps('lastName')}
                        />
                      </div>
                    )}
                  </>
                )}
                {!user?.dateOfBirthVerified && user?.userType !== 'org' && (
                  <div className="payment-user-date-wrapper">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          autoFocus={false}
                          showToolbar={false}
                          inputFormat={dateFormat}
                          label={localize('schufa.dateOfBirth.label')}
                          minDate={minDate}
                          maxDate={maxDate}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{
                                width: '100%',
                                borderBottomStyle: 'solid',
                              }}
                              error={false}
                            />
                          )}
                          disabled={user?.dateOfBirthVerified}
                          onChange={(value) => {
                            formik.setFieldValue('dateOfBirth', value);
                          }}
                          value={formik?.values?.dateOfBirth}
                        />
                      </LocalizationProvider>

                      {!formik.values?.dateOfBirth && (
                        <BrandIcon
                          name="iconIncomplete"
                          style={{
                            width: 10,
                            height: 10,
                            marginLeft: '10px',
                            marginTop: '15px',
                          }}
                        />
                      )}
                    </div>
                    {customError && (
                      <div className="date-of-birth-error">{customError}</div>
                    )}
                  </div>
                )}
                <ListItem
                  title={
                    addressStatus === 'completed' ||
                    addressStatus === 'inProgress'
                      ? formattedAddress(address, localize)
                      : localize(
                          user?.userType === 'org'
                            ? 'settings.address.org.text'
                            : 'settings.login.data.user.add.address.text'
                        )
                  }
                  leftItem={
                    <div>
                      {user?.addressVerified ? (
                        <img
                          alt="dashedPath"
                          src="/images/addressVerified.png"
                          style={{
                            width: 28,
                            height: 28,
                            marginRight: 27,
                          }}
                        />
                      ) : (
                        <BrandIcon
                          name="home"
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 35,
                          }}
                        />
                      )}
                    </div>
                  }
                  rightIcon={
                    addressStatus === 'completed'
                      ? null
                      : addressStatus === 'inProgress'
                      ? 'arrowRight'
                      : 'iconIncomplete'
                  }
                  rightIconSize={addressStatus === 'completed' ? 14 : 10}
                  onClick={
                    addressStatus === 'open'
                      ? onPressAddress
                      : addressStatus === 'inProgress'
                      ? onPressAddressInProgress
                      : onPressAddressCompleted
                  }
                  brandTextColor={addressStatus === 'open'}
                  greyTextColor={user?.addressVerified}
                  verified={user?.addressVerified}
                />
                <div className="add-sepa-text">
                  {`${localize(
                    'firstSteps.payment.sepa.description.text'
                  ).slice(
                    0,
                    expanded
                      ? localize('firstSteps.payment.sepa.description.text')
                          .length
                      : 120
                  )}${expanded ? '' : '...'}`}
                </div>
                {!expanded ? (
                  <div
                    className="add-sepa-expand-text"
                    onClick={() => onExpandPressed(true)}
                  >
                    {localize('firstSteps.payment.sepa.url.text')}
                  </div>
                ) : (
                  <div
                    className="add-sepa-expand-text"
                    onClick={() => onExpandPressed(false)}
                  >
                    {localize('less')}
                  </div>
                )}
              </div>
            </ExpandableItem>
            <div className="payment-footnote">
              <Hyperlink
                linkStyle={{ color: getBrandColor() }}
                linkText={(url) =>
                  url === localize('settings.payment.footnote.url')
                    ? localize('settings.payment.footnote.url.text')
                    : url
                }
                onPress={(url) => {
                  Linking.openURL(url);
                }}
              >
                {localize(
                  'settings.payment.footnote',
                  localize('settings.payment.footnote.url')
                )}
              </Hyperlink>
            </div>
            <div>
              <img
                className="add-sepa-logo"
                src="/images/trustLogos.png"
                alt="add-sepa-logo"
              />
            </div>
            <BrandButton
              id={GA_TITLES.ON_BOARDING.ADD_SEPA}
              title={localize('firstSteps.payment.sepa.submit.text')}
              formik={formik}
            />
          </Box>
        </div>
        {isLocalizeAdded(localize, 'sepa.optimized.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('sepa.optimized.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default React.memo(OptimizedSepaView);
